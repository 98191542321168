const staticText = {
  pageTitle: 'Login with Smartcar Connect',
  pageName: 'Smartcar Connect',
  pageContent: 'Login to your connected service account using Smartcar Connect',
  title: 'Connect Your Car',
  body: 'To start managing apps, connect your vehicle\'s OEM account.',
  buttonText: 'Connect my car',
  errorMap: {
    no_vehicles: 'There were no vehicles on your connected services account. Please add vehicles to your account, then launch Connect again.',
    invalid_subscription: 'The subscription on your connected services account is inactive. Please activate the subscription, then launch Connect again.',
    vehicle_incompatible: 'The vehicle(s) on your connected services account are not compatible with Smartcar.',
    user_manually_returned_to_application: 'There was an issue logging in through Connect. Please try logging in through Connect again.',
  },
  connectGenericError: 'Sorry, something went wrong! Please try launching Connect again.',
  popupBlocked: 'A pop-up blocker prevented Connect from launching in a new window. Disable pop-up blockers and try again.'
};

export default staticText;
