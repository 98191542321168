import { IProps } from "../../interfaces/components/RevokeAccessModal.interface";
import Modal from "../Modal";

import staticText from '../../localization/revokeAccessModal';

import api from "../../services/api";

import { useParams } from "react-router-dom";
import { useVehiclesContext } from '../../contexts/Vehicles';
import { renderAppLogo } from "../AppsList/components/AppsListItem/AppsListItem";
import { useAuthContext } from "../../contexts/Auth";

const RevokeAccessModal = ({
  appName,
  appIcon,
  applicationId,
  setModalOpen,
  setSuccess,
  setError,
}: IProps) => {
  const { vehicleId } = useParams() as { vehicleId: string };
  const { vehicles, setVehicles, selectedVehicleId, setSelectedVehicleId } = useVehiclesContext();
  const { user } = useAuthContext();

  const closeModal = () => {
    setModalOpen(false);
  };

  const postRevokeAccess = async () => {
    try {
      setError(false);
      setModalOpen(false);
      // user is for Sentry on backend
      await api.postRevokeAccess(applicationId, vehicleId, user);
      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

      const userId = vehicles[vehicleId].userId;
      // userId is used to pull vehicles, but user is for Sentry on backend
      const result = await api.getVehicles(userId, user);

      setVehicles({
        ...vehicles,
        ...result.data
      });

      setSelectedVehicleId(selectedVehicleId);
    } catch(error){
      setError(true);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      {renderAppLogo(appIcon, appName)}
      <h2 className="m-t-med">{staticText.header}</h2>
      <p>{staticText.subText(appName)}</p>
      <div className="button-set-wrapper">
        <button 
          className="button button-warning"
          onClick={postRevokeAccess}
        >
          {staticText.revokeButton}
        </button>
        <button className="button button-secondary" onClick={closeModal}>
          {staticText.cancelButton}
        </button>
      </div>
    </Modal>
  );
};

export default RevokeAccessModal;
